<script setup>
import { computed } from 'vue'
  const props = defineProps({
    redirect: String
  })
  const loginRedirect = computed(() => {
    return props.redirect ? { name: 'login', query: { redirect: props.redirect }} : { name: 'login' }
  })
</script>

<template>
  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="mx-auto w-full max-w-lg">
      <div class="mb-6">
        <h1 class="text-3xl font-bold mb-6 self-center">{{ $t('users.welcomeTitle') }}</h1>
        <p class="text-lg mb-6">{{ $t('users.welcomeInstructions') }}</p>
        <p class="text-lg">{{ $t('users.welcomeMessage') }}</p>
      </div>
      <div class="flex" :class="redirect ? 'justify-between' : 'justify-center'">
        <router-link :to="loginRedirect" class="primary-btn">{{ $t('users.login') }}</router-link>
        <button v-if="redirect" @click="$router.push(redirect)" class="secondary-btn">{{ $t('users.welcomeRedirectBtn') }}</button>
      </div>
    </div>
  </div>
</template>
