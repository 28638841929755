<script setup>
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import { elo } from "../entrypoints/utils.js";
  import { useMediaQuery } from '@vueuse/core'

  const isLargeScreen = useMediaQuery('(min-width: 1024px)')

  const store = useStore()
  const { t } = useI18n();

  const displayFormats = ref([t('normal'), t('compact')]);
  const displayFormat = ref(t('normal'))

  const participantsByRank = computed(() => {
    return [...store.getters.getTournament.participants].sort((a, b) => a.rank - b.rank);
  });
</script>

<template>
  <div id="standings-list"
  class="flex flex-col overflow-x-auto standings-pane bg-greyblue p-2 rounded-xl min-h-7/10vh">
    <SelectButton v-model="displayFormat" :options="displayFormats" aria-labelledby="basic" class="text-md self-center m-2" />
    <div class="sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full sm:px-6 lg:px-8">
        <div class="lg:flex gap-2 overflow-x-auto">
          <!-- Compact display -->
          <table v-if="displayFormat === t('compact')" class="w-full lg:w-1/2 text-sm font-light text-surface">
            <thead class="border-b border-neutral-200 font-medium">
              <tr>
                <th scope="col" class="text-start py-1" v-t="'rank'"></th>
                <th scope="col" class="text-start py-1" v-t="'name'"></th>
                <th scope="col" class="text-start py-1" v-t="'score'"></th>
                <th scope="col" class="text-start py-1" v-t="'bh'"></th>
                <th scope="col" class="text-start py-1" v-t="'rating'"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="participant in isLargeScreen ? participantsByRank.slice(0, Math.ceil(participantsByRank.length/2)) : participantsByRank"
              :key="participant.id"
              id="participant.id"
              class="border-b border-neutral-200 border-dotted"
              :class="{ 'bg-gray-700': participant === store.getters.getUserParticipant }">
                <td class="whitespace-nowrap text-ellipsis truncate overflow-hidden font-medium text-start w-1/12">
                  <div class="font-semibold">
                    {{ participant.rank }}
                  </div>
                </td>
                <td class="whitespace-nowrap text-ellipsis truncate overflow-hidden w-7/12">{{ participant.first_name }} {{ participant.last_name }}</td>
                <td class="whitespace-nowrap text-ellipsis truncate overflow-hidden w-1/12">{{ participant.score }}</td>
                <td class="whitespace-nowrap text-ellipsis truncate overflow-hidden w-1/12">{{ participant.bh }}</td>
                <td class="whitespace-nowrap text-ellipsis truncate overflow-hidden w-2/12">{{ elo(participant, store.getters.getTournament.format) }}</td>
              </tr>
            </tbody>
          </table>
          <table v-if="displayFormat === t('compact') && isLargeScreen" class="w-full lg:w-1/2 text-sm font-light text-surface">
            <thead class="hidden lg:table-header-group border-b border-neutral-200 font-medium">
              <tr>
                <th scope="col" class="text-start py-1" v-t="'rank'"></th>
                <th scope="col" class="text-start py-1" v-t="'name'"></th>
                <th scope="col" class="text-start py-1" v-t="'score'"></th>
                <th scope="col" class="text-start py-1" v-t="'bh'"></th>
                <th scope="col" class="text-start py-1" v-t="'rating'"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="participant in participantsByRank.slice(Math.ceil(participantsByRank.length/2))"
              :key="participant.id"
              id="participant.id"
              class="border-b border-neutral-200 border-dotted"
              :class="{ 'bg-gray-700': participant === store.getters.getUserParticipant }">
                <td class="whitespace-nowrap overflow-hidden text-ellipsis truncate font-medium text-start w-1/12">
                  <div class="font-semibold">
                    {{ participant.rank }}
                  </div>
                </td>
                <td class="whitespace-nowrap overflow-hidden text-ellipsis truncate w-7/12">{{ participant.first_name }} {{ participant.last_name }}</td>
                <td class="whitespace-nowrap overflow-hidden text-ellipsis truncate w-1/12">{{ participant.score }}</td>
                <td class="whitespace-nowrap overflow-hidden text-ellipsis truncate w-1/12">{{ participant.bh }}</td>
                <td class="whitespace-nowrap overflow-hidden text-ellipsis truncate w-2/12">{{ elo(participant, store.getters.getTournament.format) }}</td>
              </tr>
            </tbody>
          </table>

            <!-- Normal display -->
          <table v-if="displayFormat !== t('compact')" class="min-w-full text-sm font-light text-surface">
            <thead class="border-b border-neutral-200 font-medium">
              <tr>
                <th scope="col" class="text-center px-1 py-4" v-t="'rank'"></th>
                <th scope="col" class="text-start px-2 py-4" v-t="'name'"></th>
                <th scope="col" class="text-start px-2 py-4" v-t="'score'"></th>
                <th scope="col" class="text-start px-2 py-4" v-t="'bh'"></th>
                <th scope="col" class="text-start px-2 py-4" v-t="'rating'"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="participant in participantsByRank"
              :key="participant.id"
              id="participant.id"
              class="border-b border-neutral-200"
              :class="{ 'bg-gray-700': participant === store.getters.getUserParticipant }">
                <td class="whitespace-nowrap px-1 py-4 font-medium text-center">
                  <div v-if="[1, 2, 3].includes(participant.rank)"
                  class="text-md"
                  :class="{
                    'text-gold': participant.rank === 1,
                    'text-silver': participant.rank === 2,
                    'text-bronze': participant.rank === 3
                  }">
                    <i class="fa-solid fa-trophy"></i>
                  </div>
                  <div v-else class="font-semibold">
                    {{ participant.rank }}
                  </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4">{{ participant.first_name }} {{ participant.last_name }}</td>
                <td class="whitespace-nowrap px-3 py-4">{{ participant.score }}</td>
                <td class="whitespace-nowrap px-3 py-4">{{ participant.bh }}</td>
                <td class="whitespace-nowrap px-3 py-4">{{ elo(participant, store.getters.getTournament.format) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
