<script setup>
  import { ref, onMounted } from 'vue';
  import { useRouter } from "vue-router";
  import { useToast } from "primevue/usetoast";
  import api from "../entrypoints/api.js";
  import axios from "axios";
  import { seconds2scale, scale2seconds } from '../entrypoints/utils.js';

  const toast = useToast();
  const router = useRouter();

  const props = defineProps(['isNewTournament'])

  const fieldErrors = ref({})
  const tournamentId = ref(null)
  const formData = ref({
    title: null,
    date: null,
    end_date: null,
    system: "swiss",
    federation: 'friendly',
    format: 'standard',
    max_participants: null,
    description: null,
    max_rounds: null,
    address: "",
    time_initial: null,
    time_per_move: null,
  });
  const timeSelects = ref({
    initial: "s",
    perMove: "s",
  })

  async function handleFormValidation() {
    if(props.isNewTournament) {
      // Create a new tournament
      const response = await api.createTournament(formData.value, timeSelects.value);

      if (response.error.value) {
        fieldErrors.value = response.error.value.response?.data?.error || {};
        toast.add({ severity: 'error', summary: "Error creating tournament", life: 3000 });
      } else {
        toast.add({ severity: 'success', summary: `New tournament created`, life: 3000 });
        router.push(`/tournaments/${response.data.value.tournament.id}`);
      }
    } else {
      const tournamentData = {...formData.value};
      tournamentData.time_initial = scale2seconds(tournamentData.time_initial, timeSelects.value.initial);
      tournamentData.time_per_move = scale2seconds(tournamentData.time_per_move, timeSelects.value.perMove);

      // Update tournament data
      axios.put(`/api/tournaments/${tournamentId.value}`, {
        tournament: tournamentData
      })
      .then(() => {
        router.push(`/tournaments/${tournamentId.value}`)
      })
      .catch((error) => {
        fieldErrors.value = error.response?.data?.error || {};
        toast.add({ severity: 'error', summary: 'Cannot update the tournament data', life: 3000 });
      })
    }
  }

  async function fetchTournamentData() {
    const response = await api.getTournament(tournamentId.value);

    if (response.data.value) {
      formData.value = {
        title: response.data.value.tournament.title,
        date: new Date(response.data.value.tournament.date),
        federation: response.data.value.tournament.federation,
        format: response.data.value.tournament.format,
        max_participants: response.data.value.tournament.max_participants,
        description: response.data.value.tournament.description,
        end_date: new Date(response.data.value.tournament.end_date),
        system: response.data.value.tournament.system,
        max_rounds: response.data.value.tournament.max_rounds,
        address: response.data.value.tournament.address,
        time_initial: response.data.value.tournament.time_initial,
        time_per_move: response.data.value.tournament.time_per_move,
      };

      const initialTime = seconds2scale(formData.value.time_initial);
      formData.value.time_initial = initialTime.value;
      timeSelects.value.initial = initialTime.scale;
      const perMoveTime = seconds2scale(formData.value.time_per_move);
      formData.value.time_per_move = perMoveTime.value;
      timeSelects.value.perMove = perMoveTime.scale;

      tournamentId.value = response.data.value.tournament.id
    } else {
      console.error(response.error.value.message);
    }
  }

  onMounted(() => {
    if(!props.isNewTournament) {
      tournamentId.value = router.currentRoute.value.params.id
      fetchTournamentData();
    } else {
      timeSelects.initial = "m";
    }
  })
</script>

<template>
  <form @submit.prevent="handleFormValidation()">
    <div class="flex flex-col gap-3">
      <div class="flex flex-col md:flex-row w-full gap-3 md:gap-2">
        <div class="flex flex-grow flex-col gap-2">
          <label class="text-light-grey" v-t="'tournament.form.titleLabel'"></label>
          <InputText id="username" v-model="formData.title" class="" placeholder="Your tournament title" required="true" />
          <div v-if="fieldErrors.title" class="text-sm text-red-500">{{ fieldErrors.title[0] }}</div>
        </div>
        <div class="md:w-1/4 flex flex-col gap-2">
          <label for="max-players" class="text-light-grey" v-t="'tournament.form.maxParticipants'"></label>
          <InputText id="max-players" type="number" v-model="formData.max_participants" class="" placeholder="20" required="true" />
          <div v-if="fieldErrors.max_participants" class="text-sm text-red-500">{{ fieldErrors.max_participants[0] }}</div>
        </div>
        <div class="md:w-1/4 flex flex-col gap-2">
          <label for="max-rounds" class="text-light-grey" v-t="'tournament.form.maxRoundsLabel'"></label>
          <InputText id="max-rounds" type="number" v-model="formData.max_rounds" class="" placeholder="9" />
          <div v-if="fieldErrors.max_rounds" class="text-sm text-red-500">{{ fieldErrors.max_rounds[0] }}</div>
        </div>
      </div>
      <div class="flex flex-grow flex-col gap-2">
        <label for="address" class="text-light-grey" v-t="'tournament.form.addressLabel'"></label>
        <InputText id="address" v-model="formData.address" class="p-2" placeholder="Your tournament adress" :required="formData.federation === 'ffe'" />
          <div v-if="fieldErrors.address" class="text-sm text-red-500">{{ fieldErrors.address[0] }}</div>
        </div>
      <div class="flex flex-col sm:flex-row gap-2 justify-between">
        <div class="sm:w-1/2 flex flex-col gap-2">
          <label for="date" class="text-light-grey" v-t="'tournament.form.dateLabel'"></label>
          <Calendar id="date" v-model="formData.date" showTime hourFormat="24" class="rounded-md" placeholder="04/06/2024 14:30" required="true" />
          <div v-if="fieldErrors.date" class="text-sm text-red-500">{{ fieldErrors.date[0] }}</div>
        </div>
        <div class="sm:w-1/2 flex flex-col gap-2">
          <label for="end-date" class="text-light-grey" v-t="'tournament.form.endDateLabel'"></label>
          <Calendar id="end-date" v-model="formData.end_date" showTime hourFormat="24" class="rounded-md" placeholder="04/08/2024 14:30" :required="formData.federation === 'ffe'" />
          <div v-if="fieldErrors.end_date" class="text-sm text-red-500">{{ fieldErrors.end_date[0] }}</div>
        </div>
      </div>

      <div class="flex flex-col sm:flex-row w-full gap-3 sm:gap-2">
        <div class="flex flex-col gap-2 flex-grow">
          <label for="federation" class="text-light-grey" v-t="'tournament.form.federation'"></label>
          <select v-model="formData.federation" :disabled="!isNewTournament" required="true"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md flex-grow">
            <option value="friendly" :selected="formData.federation == 'friendly'">{{ $t('tournament.form.friendly') }}</option>
            <option value="ffe" :selected="formData.federation == 'ffe'">FFE</option>
          </select>
          <div v-if="fieldErrors.federation" class="text-sm text-red-500">{{ fieldErrors.federation[0] }}</div>
        </div>
        <div class="flex flex-col gap-2 flex-grow">
          <label for="format" class="text-light-grey" v-t="'tournament.form.format'"></label>
          <select v-model="formData.format" id="format"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md flex-grow"
          required="true" >
            <option value="standard" :selected="formData.format === 'standard'">{{ $t('tournament.form.standard') }}</option>
            <option value="rapid" :selected="formData.format === 'rapid'">{{ $t('tournament.form.rapid') }}</option>
            <option value="blitz" :selected="formData.format === 'blitz'">{{ $t('tournament.form.blitz') }}</option>
          </select>
          <div v-if="fieldErrors.format" class="text-sm text-red-500">{{ fieldErrors.format[0] }}</div>
        </div>
        <div class="flex flex-col gap-2 flex-grow">
          <label for="system" class="text-light-grey" v-t="'tournament.form.system'"></label>
          <select v-model="formData.system"
          id="system"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md flex-grow">
            <option value="swiss" :selected="formData.system === 'swiss'" required="true">{{ $t('tournament.form.swiss') }}</option>
          </select>
          <div v-if="fieldErrors.system" class="text-sm text-red-500">{{ fieldErrors.system[0] }}</div>
        </div>
      </div>

      <div class="flex flex-col gap-2 flex-grow">
        <label for="time-initial" class="text-light-grey" v-t="'tournament.form.timeInitial'"></label>
        <div class="flex items-center gap-2">
          <div>
            <InputNumber id="time-initial" v-model="formData.time_initial" class="flex-grow" placeholder="15" :required="formData.federation === 'ffe'" />
            <div v-if="fieldErrors.time_initial" class="text-sm text-red-500">{{ fieldErrors.time_initial[0] }}</div>
          </div>
          <select v-model="timeSelects.initial" name="time-initial-select" id="time-initial-select"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md flex-grow">
            <option value="s">{{ $t('tournament.form.seconds') }}</option>
            <option value="m">{{ $t('tournament.form.minutes') }}</option>
            <option value="h">{{ $t('tournament.form.hours') }}</option>
            <option value="d">{{ $t('tournament.form.days') }}</option>
          </select>
        </div>
      </div>
      <div class="flex flex-col gap-2 flex-grow">
        <label for="time-per-move" class="text-light-grey" v-t="'tournament.form.timePerMove'"></label>
        <div class="flex items-center gap-2">
          <div>
            <InputNumber id="time-per-move" v-model="formData.time_per_move" class="flex-grow" placeholder="0" :required="formData.federation === 'ffe'" />
            <div v-if="fieldErrors.time_per_move" class="text-sm text-red-500">{{ fieldErrors.time_per_move[0] }}</div>
          </div>
          <select v-model="timeSelects.perMove" name="time-per-move-select" id="time-initial-select"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md flex-grow">
            <option value="s">{{ $t('tournament.form.seconds') }}</option>
            <option value="m">{{ $t('tournament.form.minutes') }}</option>
            <option value="h">{{ $t('tournament.form.hours') }}</option>
            <option value="d">{{ $t('tournament.form.days') }}</option>
          </select>
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <label for="description" class="text-light-grey" v-t="'tournament.form.descriptionLabel'"></label>
        <Textarea id="description" v-model="formData.description" rows="8" cols="90" class="" placeholder="Your tournament details..." />
        <div v-if="fieldErrors.description" class="text-sm text-red-500">{{ fieldErrors.description[0] }}</div>
      </div>
      <div class="self-end">
        <button type="submit" class="primary-btn" v-t="'confirmBtn'"></button>
      </div>
    </div>
  </form>
</template>
