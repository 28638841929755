<script setup>
  import { ref, computed } from 'vue';
  import { useConfirm } from "primevue/useconfirm";
  import { computeParticipantPaired, hasUserPlayerRights, hasUserActivationRights, initServiceWorker} from "../entrypoints/utils.js";
  import api from "../entrypoints/api.js";
  import { elo } from "../entrypoints/utils.js";
  import { useToast } from 'primevue/usetoast';
  import { useI18n } from 'vue-i18n';
  import axios from 'axios';
  import { useStore } from 'vuex'

  // Services
  const store = useStore();
  const toast = useToast();
  const confirm = useConfirm();
  const { t } = useI18n();

  const props = defineProps(['participant', 'index']);
  const emit = defineEmits(['toggleActivation', 'deleteParticipant']);

  const tournament = computed(() => store.getters.getTournament)
  const editMode = ref(false);
  const editedParticipant = ref({...props.participant});
  const editedParticipantElo = ref(elo(props.participant, tournament.value.format));

  // Local variables
  const isPaired = computed(() => {
    return computeParticipantPaired(tournament.value.rounds, props.participant.id)
  });

  function confirmDeleteParticipant(event) {
    confirm.require({
      target: event.currentTarget,
      message: t('confirm.deleteParticipantMessage', {firstName: props.participant.first_name, lastName: props.participant.last_name}),
      icon: 'pi pi-exclamation-triangle',
      rejectLabe: t('confirm.no'),
      acceptLabel: t('confirm.yes'),
      accept: () => emit('deleteParticipant'),
      group: 'dialog',
    });
  }

  function toggleEditMode() {
    editMode.value = !editMode.value;
  }

  async function updateParticipant() {
    editedParticipant.value.elo_blitz = editedParticipant.value.elo_standard;
    editedParticipant.value.elo_rapid = editedParticipant.value.elo_standard;
    const response = await api.updateParticipant(editedParticipant.value);

    if (!response.error.value) {
      editMode.value = false;
    }
    if (response.error.value) {
      toast.add({ severity: 'error', summary: response.error.value.response.data.message, life: 3000 });
    }
  }

  function refreshParticipantFfeData() {
    axios.get(`/api/participants/${props.participant.id}/refresh`)
    .then(() => {
      toast.add({ severity: "success", summary: `${props.participant.first_name} ${props.participant.last_name} has been updated`, life: 3000 })
    })
    .catch(error => {
      toast.add({ severity: "error", summary: error.response.data.message, life: 3000 })
    })
  }

  async function toggleCheckin() {
    axios.put(`/api/participants/${editedParticipant.value.id}/toggle_checkin`)
    .then(response => {
    })
    .catch(error => {
      toast.add({ severity: "error", summary: error.response.data.message, life: 3000 })
    })
  }

  const participantMenu = ref();
  const items = computed(() => {
    const menuItems = [];
    const activationItems = { label: t("adminActions.activationsSectionTitle"), items: [] };
    if (props.participant.checkin) {
      activationItems.items.push({ label: props.participant.active ? "Deactivate" : "Activate", icon: `fa-regular ${props.participant.active ? "fa-eye-slash text-warning" : "fa-eye text-success"}`, command: () => emit('toggleActivation') })
      activationItems.items.push({ label: t("adminActions.uncheck"), icon: "fa-solid fa-xmark text-xl text-danger", command: () => toggleCheckin() })
    } else {
      activationItems.items.push({ label: t("adminActions.check"), icon: "fa-solid fa-check text-xl text-success", command: () => toggleCheckin() })
    }
    menuItems.push(activationItems)

    if (hasUserPlayerRights(tournament.value)) {
      const registrationItems = { label: t('adminActions.registrationsSectionTitle'), items: [] }
      if (props.participant.id_ffe) {
        registrationItems.items.push({ label: t("refresh"), icon: "fa-solid fa-arrow-rotate-right text-lg text-blue-300", command: () => refreshParticipantFfeData() })
      }
      registrationItems.items.push({ label: t("edit"), icon: "fa-solid fa-pen-to-square text-lg text-amber-600", command: () => toggleEditMode() })
      registrationItems.items.push({ label: t('delete'), icon: "fa-solid fa-user-xmark text-danger", command: (event) => confirmDeleteParticipant(event), disabled: isPaired.value })
      menuItems.push(registrationItems)
    }

    return menuItems
  })

  const toggleMenu = (event) => {
    participantMenu.value.toggle(event);
  };

  async function followParticipant() {
    store.dispatch('followParticipant', props.participant);

    const init = await initServiceWorker();
    if (init.webpushSubscription && !!props.participant) {
      api.subscribeWebpush(store.getters.getAuthToken, store.getters.getUserParticipant?.id || null)
    } else {
      console.warn("Subscription initialization fail");
    }
  }

  async function unfollowParticipant() {
    const init = await initServiceWorker();
    if (init.webpushSubscription) {
      api.unsubscribeWebpush(init.webpushSubscription, store.getters.getUserParticipant?.id)
    } else {
      console.warn("Subscription initialization fail");
    }
    store.dispatch('followParticipant', null);
  }
</script>

<template>
  <button @click="emit('toggleActivation')"
  :disabled="!hasUserActivationRights(tournament)"
  class="absolute inset-x-0 w-11 h-7 rounded-r-md text-center justify-end"
  :class="!participant.checkin ? 'bg-danger' : participant.active ? 'bg-success' : 'bg-warning'">
    <p class="absolute text-xl w-full mb-0 inset-x-0 bottom-0 text-center">{{ index + 1 }}</p>
  </button>

  <div v-show="!editMode"
  class="flex justify-between items-center flex-grow gap-2 overflow-hidden">
    <div class="overflow-hidden">
      <p class="text-xl m-0">{{ participant.first_name }}</p>
      <p class="text-xl m-0">{{ participant.last_name }}</p>
    </div>
    <p class="text-xl m-0 flex-shrink">{{ elo(participant, tournament.format) }}</p>

    <div>
      <button v-if="!store.getters.isAuthenticated && store.getters.getUserParticipant !== participant"
      @click="followParticipant"
      class="primary-btn">
        <p>{{ $t('tournament.index.follow') }}</p>
      </button>
      <button v-else-if="!store.getters.isAuthenticated && !(store.getters.getUserParticipant !== participant)"
      @click="unfollowParticipant"
      class="warning-btn">
        <p>{{ $t('tournament.index.unfollow') }}</p>
      </button>
    </div>

    <div v-if="hasUserActivationRights(tournament)"
    class="flex gap-2">
      <div>
        <button type="button" class="fa-solid fa-ellipsis-vertical text-3xl px-2" @click="toggleMenu" aria-haspopup="true" aria-controls="overlay_menu" />
        <Menu ref="participantMenu" id="overlay_menu" :model="items" :popup="true" />
      </div>
    </div>
  </div>

  <form v-if="hasUserPlayerRights(tournament)"
  @submit.prevent="updateParticipant"
  v-show="editMode"
  class="flex justify-between gap-2">
    <div v-if="tournament.federation === 'ffe' || participant.id_ffe"
    class="flex justify-between items-center gap-1 mr-2">
      <input v-model="editedParticipant.id_ffe" class="text-xl rounded bg-deep-blue border border-gray-500 w-full" placeholder="W55755" />
    </div>
    <div v-else class="flex justify-between flex-shrink-1 items-center gap-1 mr-2">
      <div class="w-3/4">
        <input v-model="editedParticipant.first_name" class="mb-1 text-xl rounded bg-deep-blue border border-gray-500 w-full" placeholder="Mike" />
        <input v-model="editedParticipant.last_name" class="text-xl rounded bg-deep-blue border border-gray-500 w-full" placeholder="Smith" />
      </div>
      <input type="numeric" v-model="editedParticipant.elo_standard" class="text-xl rounded bg-deep-blue border border-gray-500 w-1/4" placeholder="1234" />
    </div>

    <div class="flex gap-2">
      <button type="submit">
        <div class="text-2xl text-success">
          <i class="fa-solid fa-check"></i>
        </div>
      </button>
      <button type="button" @click="toggleEditMode">
        <div class="text-2xl text-gray-400">
          <i class="fa-solid fa-x text-danger"></i>
        </div>
      </button>
    </div>
  </form>
</template>
