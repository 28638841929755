<script setup>
  import { ref, watch, reactive, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useConfirm } from "primevue/useconfirm";
  import { isRoundMissingResults, hasUserRoundRights } from "../entrypoints/utils.js";
  import { useI18n } from 'vue-i18n';
  import { useToast } from 'primevue/usetoast';
  import axios from 'axios';
  import api from '../entrypoints/api.js';
  import { handleFileDownload, isUserAdmin } from '../entrypoints/utils.js';
  import { useMediaQuery } from '@vueuse/core';

  import FileUpload from 'primevue/fileupload';
  import RoundPairing from './RoundPairing.vue';

  const store = useStore();
  const toast = useToast();
  const confirm = useConfirm();
  const { t } = useI18n();

  const pendingCreateRound = ref(false);
  const pendingDeleteRound = ref(false);
  const autoRound = computed({
    get() {
      return store.getters.getTournament?.auto_rounds // Valeur retournée pour la lecture
    },
    set() {
      toggleAutoRounds() // Appelle la fonction lorsque la valeur change
    }
  });
  const participantMenuRef = ref();
  const countdown = ref(0);
  const notifyPlayersEnabled = ref(true);
  const NOTIF_INTERVAL = 120;
  const isLargeScreen = useMediaQuery('(min-width:1024px)');
  const displayFormats = ref([t('normal'), t('compact')]);
  const displayFormat = ref(t('normal'));
  const roundFileUpload = ref([]);
  const currentRound = computed(() => store.getters.getCurrentRound);
  const sortedRounds = computed(() => {
    const tmpRounds = [...store.getters.getTournament.rounds].sort((a, b) => a.index - b.index)

    if(!hasUserRoundRights)
      tmpRounds.filter(round => round.status !== "draft")

    return tmpRounds
  });
  const sortedPairings = computed(() => {
  return sortedRounds.value[visibleRoundIndex.value - 1].pairings.sort((a, b) => a.table - b.table)
  })
  const hideOverGames = ref(false);
  const isLastRoundMissingResults = computed(() => {
    if (currentRound.value) {
      return !!isRoundMissingResults(currentRound.value);
    }
    return false
  });
  const visibleRoundIndex = ref(sortedRounds.value.length)
  const roundDisplayed = computed(() => sortedRounds.value[visibleRoundIndex.value - 1])

  const newRoundItems = [
    {
      label: 'Draft',
      command: () => {
        createRound({status: 'draft'})
      }
    },
  ];

  function confirmDeleteRound(event) {
    confirm.require({
      target: event.currentTarget,
      header: t('confirm.deleteRoundHeader'),
      message: t('confirm.deleteRoundMessage', {roundIndex: visibleRoundIndex.value}),
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: t('confirm.cancel'),
      acceptLabel: t('confirm.deleteRound', {roundIndex: visibleRoundIndex.value}),
      accept: deleteRound,
      group: 'dialog',
    });
  }

  watch(sortedRounds, () => {
    if (visibleRoundIndex.value >= sortedRounds.value.length - 1 ) {
      visibleRoundIndex.value = sortedRounds.value.length
    }
  });

  watch(visibleRoundIndex, () => {
    hideOverGames.value = false;
  });

  async function deleteRound() {
    pendingDeleteRound.value = true;
    const deleteRoundIndex = roundDisplayed.value.index;
    const response = await api.deleteRound(roundDisplayed.value.id)

    pendingDeleteRound.value = false;
    if (response.error) {
      toast.add({ severity: 'error', summary: response.error.response.data.message, life: 3000 })
    } else {
      toast.add({ severity: 'success', summary: `Round ${deleteRoundIndex} deleted`, life: 3000 })
    }
  }

  async function createRound(params) {
    pendingCreateRound.value = true;
    const response = await api.createRound(store.getters.getTournament.id, params);
    pendingCreateRound.value = false;

    if (response.error) {
      toast.add({ severity: 'error', summary: response.error.response.data.message, life: 3000 });
    } else {
      toast.add({ severity: 'success', summary: `New round created`, life: 3000 });
    }
  }

  async function publishRound(round) {
    const response = await api.publishRound(round);

    if (response.error) {
      toast.add({ severity: 'error', summary: response.error.response.data.message, life: 3000 })
    } else {
      toast.add({ severity: 'success', summary: `Round ${round.index} published`, life: 3000 })
    }
  }

  async function downloadRound(round) {
    try {
      const response = await axios.get(`/api/rounds/${round.id}/download`, {
        headers: {
          'Accept': 'text/csv',
        },
        responseType: "blob",
      });

      handleFileDownload(response, `round_${round.index}.csv`);
    } catch (error) {
      console.error(error);
      toast.add({ severity: 'error', summary: 'Error downloading round file', detail: error.response.data.message, life: 3000 });
    }
  }

  async function downloadRoundTemplate() {
    try {
      const response = await axios.get('/api/download_custom_round_template', {
        responseType: "blob",
      });

      handleFileDownload(response, 'round_template.csv')
    } catch (error) {
      toast.add({ severity: 'error', summary: 'Error downloading round template', life: 3000 });
    }
  }

  function uploadRound(round) {
    const files = roundFileUpload.value[round.index].files;

    if (files.length === 0) {
      console.error('No file selected.');
      return;
    }

    const formData = new FormData();
    formData.append('file', files[0]);

    axios
      .put(`/api/rounds/${round.id}/upload`, formData)
      .then(response => {
        toast.add({ severity: 'success', summary: 'Successfully uploaded round', life: 3000 })
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        toast.add({ severity: 'error', summary: error.response.data.message, life: 3000 })
      });
  }

  function resetRoundPairings(round) {
    axios
      .put(`/api/rounds/${round.id}/reset_pairings`)
      .then(response => {
        toast.add({ severity: 'success', summary: 'Reset successfull', life: 3000 })
      })
      .catch(error => {
        console.error('Reset error:', error);
        toast.add({ severity: 'error', summary: error.response.data.message, life: 3000 })
      });
  }

  async function downloadTournamentPlayers() {
    try {
      const response = await axios.get(`/api/tournaments/${store.getters.getTournament.id}/download_players`, {
        responseType: "blob",
        headers: {
          'Accept': 'text/csv',
        }
      });

      handleFileDownload(response, `${store.getters.getTournament.title}_players.csv`);
    } catch (error) {
      toast.add({ severity: 'error', summary: 'Error downloading players', life: 3000 });
      console.error(error);
    }
  }

  function setIllegalMoves(pairingId, color, illegalMoves) {
    axios.put(`/api/pairings/${pairingId}/set_illegal_moves`, {color: color, illegal_moves: illegalMoves})
    .then()
    .catch(error => {
      toast.add({ severity: "error", summary: error.response.data.message, life: 3000 })
    })
  }

  const participantMenu = reactive({
    pairing: {},
    color: null,
    illegalMoves: 0,
  })
  const items = computed(() => {
    const itemsList = [{
      label: "Illegal moves",
      items: [{
        label: "Add",
        icon: "fa-solid fa-plus",
        command: () => setIllegalMoves(participantMenu.pairing.id, participantMenu.color, participantMenu.illegalMoves + 1)
      }]
    }]

    if (participantMenu.illegalMoves > 0) {
      itemsList[0].items.push({
        label: "Remove",
        icon: "fa-solid fa-minus",
        command: () => setIllegalMoves(participantMenu.pairing.id, participantMenu.color, participantMenu.illegalMoves - 1)
      })
    }
    return itemsList
  })

  const toggleParticipantMenu = (pairing, color, event) => {
    participantMenu.pairing = pairing
    participantMenu.color = color
    if (participantMenu.color == "white") {
      participantMenu.illegalMoves = pairing.white_illegal_moves;
    } else {
      participantMenu.illegalMoves = pairing.black_illegal_moves;
    }
    participantMenuRef.value.toggle(event)
  }

  async function notifyRemainingPlayers() {
    const response = await api.notifyRemainingPlayers(store.getters.getTournament.id);
    if (!response.error.value) {
      notifyPlayersEnabled.value = false;
      countdown.value = NOTIF_INTERVAL;
      const interval = setInterval(() => {
        countdown.value -= 1;
        if(countdown.value <= 0) {
          notifyPlayersEnabled.value = true;
          clearInterval(interval);
        }
      }, 1000);
      toast.add({ severity: 'success', summary: `Players notified`, life: 3000 });
    }
    if (response.error.value) {
      toast.add({ severity: 'error', summary: response.error.value.response.data, life: 3000 });
    }
  }

  function toggleAutoRounds() {
    axios.put(`/api/tournaments/${store.getters.getTournament.id}/set_auto_rounds`, {
      auto_round: !store.getters.getTournament.auto_rounds,
    })
    .catch((err) => {
      toast.add({ severity: "error", summary: err.response.data.error, life: 6000 });
    });
  }
</script>

<template>
  <div>
    <Menu ref="participantMenuRef" id="overlay_menu" :model="items" :popup="true" />
    <div class="rounds-pane">
      <div class="bg-greyblue rounded-lg pb-1 min-h-[70vh] flex flex-col">
        <div class="flex items-center justify-between py-3 px-4 border-b-2 border-light-grey">
          <button @click="visibleRoundIndex--"
          :class="{ 'opacity-40': !currentRound || visibleRoundIndex == 1 }"
          :disabled="!currentRound || visibleRoundIndex == 1">
            <div class="bg-deep-greyblue text-xl w-8 text-center border-1 border-light-grey rounded-md object-center">
              <i class="fa-solid fa-chevron-left object-center"></i>
            </div>
          </button>
          <select v-model="visibleRoundIndex"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md">
            <option v-for="(round) in sortedRounds" :key="round.id"
            :value="round.index">
              {{ $t('Round') }} {{ round.index }}
              <div>
                <i class="fa-solid fa-chevron-down"></i>
              </div>
            </option>
            <option v-if="!currentRound" value='' v-t="'noRound'"></option>
          </select>
          <button @click="visibleRoundIndex++"
          :class="{ 'opacity-40': !currentRound || visibleRoundIndex == sortedRounds.length }"
          :disabled="!currentRound || visibleRoundIndex == sortedRounds.length">
            <div class="bg-deep-greyblue text-xl w-8 text-center border-1 border-light-grey rounded-md object-center">
              <i class="fa-solid fa-chevron-right"></i>
            </div>
          </button>
        </div>
        <div class="flex justify-around gap-1 m-3">
          <div v-if="hasUserRoundRights(store.getters.getTournament) && store.getters.getTournament.status != 'over'"
          v-show="visibleRoundIndex > 0 && (visibleRoundIndex == currentRound?.index || roundDisplayed.status == 'draft')">
            <button @click="confirmDeleteRound"
            v-show="!pendingDeleteRound"
            class="danger-btn flex gap-2 items-center">
              <i class="fa-solid fa-trash-can"></i>
              <p class="hidden md:inline-block" v-t="'delete'"></p>
            </button>
            <ProgressSpinner v-show="pendingDeleteRound" style="width:50px; height:50px" strokeWidth="4" />
          </div>
          <div v-if="store.getters.getTournament.status != 'over'"
          v-show="visibleRoundIndex > 0 && visibleRoundIndex === currentRound?.index">
            <button class="secondary-btn"
            @click="hideOverGames = !hideOverGames">{{ hideOverGames ? $t('showAllGames') : $t('hideGamesOver') }}</button>
          </div>
          <div v-if="hasUserRoundRights(store.getters.getTournament) && store.getters.getTournament.status != 'over'">
            <button v-show="!pendingCreateRound" class="primary-btn flex gap-2 items-center"
            :class="{ 'opacity-40': visibleRoundIndex > 0 && isLastRoundMissingResults }"
            :disabled="visibleRoundIndex > 0 && isLastRoundMissingResults"
            @click="createRound({})">
              <i class="fa-solid fa-plus"></i>
              <p class="hidden md:inline-block" v-t="'newRound'"></p>
            </button>
            <ProgressSpinner v-show="pendingCreateRound" style="width:50px; height:50px" strokeWidth="4" />
          </div>
        </div>
        <div v-if="hasUserRoundRights(store.getters.getTournament)"
        class="flex flex-col md:flex-row flex-wrap w-full items-center gap-3 justify-evenly text-center mb-3 p-2"
        v-show="visibleRoundIndex > 0 || !currentRound">
          <div v-if="store.getters.getTournament.status == 'running' && isUserAdmin"
          v-show="visibleRoundIndex > 0 && visibleRoundIndex == currentRound?.index">
            <h5 v-if="notifyPlayersEnabled" class="mb-2" v-t="'notifyPlayersMessage'"></h5>
            <h5 v-if="!notifyPlayersEnabled" class="mb-2" :class="{ 'opacity-40': !notifyPlayersEnabled }">{{ $t("waitNotifyMessage") }}: {{ countdown }} s</h5>
            <button @click="notifyRemainingPlayers"
            class="warning-btn"
            :class="{ 'opacity-40': !notifyPlayersEnabled }"
            :disabled="!notifyPlayersEnabled"
            v-t="'notifyPlayersAction'"></button>
          </div>
          <button v-if="hasUserRoundRights(store.getters.getTournament)"
          v-show="currentRound"
          @click="downloadRound(roundDisplayed)"
          class="button bg-stone-600">{{ $t('tournament.edit.downloadRoundAction')}}</button>
          <div v-if="hasUserRoundRights(store.getters.getTournament) && store.getters.getTournament.status != 'over'" class="text-center items-center"
          v-show="!currentRound || visibleRoundIndex > 0 && visibleRoundIndex == currentRound?.index">
            <label for="default-activation" class="block font-medium text-light-grey">{{ $t('autoRounds') }}</label>
            <Checkbox v-model="autoRound" inputId="default-activation" binary class="align-middle" :disabled="store.getters.getTournament.max_rounds && store.getters.getTournament.rounds.length > store.getters.getTournament.max_rounds" />
          </div>
        </div>
        <SelectButton v-model="displayFormat" :options="displayFormats" aria-labelledby="basic" class="text-sm text-center mb-4" />
        <div v-if="sortedRounds.length == 0" class="flex flex-1 justify-center items-center">
          <p class="text-xl">{{ $t('noRoundPublishedMessage') }}</p>
        </div>
        <div v-else id="rounds-list" class="m-2 flex flex-col flex-1 justify-center">
          <div v-if="roundDisplayed.status === 'draft'"
          class="bg-lime-300/30 w-11/12 rounded-md text-center border border-lime-700/60 shadow mb-3 mx-auto">
            <h1 class="text-xl font-bold">Round draft</h1>
            <p class="text-md">This round has not been published yet, only you can see this round</p>
            <p class="text-md mb-5">You can edit this round by uploading a CSV file</p>
            <div class="flex justify-around items-center">
              <div>
                <FileUpload class="mb-3" mode="basic" name="demo[]" accept=".csv" :maxFileSize="1000000" :chooseLabel="$t('browse')" :ref="el => roundFileUpload[roundDisplayed.index] = el" />
                <button class="primary-btn mb-2" @click="uploadRound(roundDisplayed)">{{ $t('confirmBtn') }}</button>
                <div class="flex-auto items-center text-indigo-200 mb-1">
                  <i class="fa-solid fa-file-import text-lg mr-2"></i>
                  <button class="text-md underline underline-offset-1" @click="downloadRoundTemplate" v-t="'registrationModal.templateDownload'"></button>
                </div>
                <div class="flex-auto items-center text-indigo-200 mb-1">
                  <i class="fa-solid fa-file-import text-lg mr-2"></i>
                  <button class="text-md underline underline-offset-1" @click="downloadTournamentPlayers" v-t="'tournament.edit.downloadPlayersAction'"></button>
                </div>
              </div>
              <button @click="resetRoundPairings(roundDisplayed)" class="warning-btn">Reset pairings</button>
              <button @click="publishRound(roundDisplayed)" class="success-btn">Publish !</button>
            </div>
          </div>
          <div v-if="!isRoundMissingResults(roundDisplayed) && hideOverGames" class="flex flex-1 justify-center w-full text-xl">
            <p>{{ $t('overRoundMessage') }}</p>
          </div>
          <div v-else>
            <div v-if="displayFormat === $t('compact')" id="round.id-pairings-list" class="flex flex-col overflow-x-auto standings-pane bg-greyblue p-2 rounded-xl min-h-7/10vh">
              <div class="sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="lg:flex gap-2 overflow-x-auto">
                    <table class="w-full lg:w-1/2 text-sm font-light text-surface">
                      <thead class="border-b border-neutral-200 font-medium">
                        <tr>
                          <th scope="col" class="text-start py-1" v-t="'table'"></th>
                          <th scope="col" class="text-start py-1" v-t="'white'"></th>
                          <th scope="col" class="text-start py-1 px-1" v-t="'result'"></th>
                          <th scope="col" class="text-start py-1" v-t="'black'"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="pairing in isLargeScreen ? sortedPairings.slice(0, [Math.ceil(sortedPairings.length/2)]) : sortedPairings"
                        :key="pairing.id"
                        v-show="!hideOverGames || pairing.result == 'pending'"
                        id="round_round.id-pairing_pairing.id %>">
                          <RoundPairing @toggleParticipantMenu="(pairing, color, event) => toggleParticipantMenu(pairing, color, event)" :round="roundDisplayed" :pairing="pairing" :displayFormat="displayFormat" />
                        </tr>
                      </tbody>
                    </table>
                    <table v-if="isLargeScreen" class="w-1/2 text-sm font-light text-surface">
                      <thead class="border-b border-neutral-200 font-medium">
                        <tr>
                          <th scope="col" class="text-start py-1" v-t="'table'"></th>
                          <th scope="col" class="text-start py-1" v-t="'white'"></th>
                          <th scope="col" class="text-start py-1 px-1" v-t="'result'"></th>
                          <th scope="col" class="text-start py-1" v-t="'black'"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="pairing in sortedPairings.slice([Math.ceil(sortedPairings.length/2)])"
                        :key="pairing.id"
                        v-show="!hideOverGames || pairing.result == 'pending'"
                        id="round_round.id-pairing_pairing.id %>">
                          <RoundPairing @toggleParticipantMenu="(pairing, color, event) => toggleParticipantMenu(pairing, color, event)" :round="roundDisplayed" :pairing="pairing" :displayFormat="displayFormat" />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="displayFormat !== t('compact')" id="round.id-pairings-list">
              <div v-for="pairing in sortedPairings"
              v-show="!hideOverGames || pairing.result == 'pending'"
              id="round_round.id-pairing_pairing.id %>"
              :key="pairing.id">
                <RoundPairing @toggleParticipantMenu="(pairing, color, event) => toggleParticipantMenu(pairing, color, event)" :round="roundDisplayed" :pairing="pairing" :displayFormat="displayFormat" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
