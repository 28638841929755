<script setup>
  import { ref, computed } from 'vue';
  import moment from 'moment';
  import { hasUserPlayerRights, isUserAdmin, elo } from "../entrypoints/utils.js";
  import api from '../entrypoints/api.js'
  import { useToast } from 'primevue/usetoast';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';

  import TournamentUserButtons from "./TournamentUserButtons.vue";
  import ParticipantRegistration from "./ParticipantRegistration.vue";
  import AdminActionsMenu from './AdminActionsMenu.vue';
  import RegistrationMenu from './RegistrationMenu.vue';

  const toast = useToast();
  const { t } = useI18n();
  const store = useStore();

  const tournament = computed(() => store.getters.getTournament)
  const sortingOptions = ref([t('rating'), t('name')]);
  const sortingMethod = ref(t('rating'));
  const initTime = computed(() => seconds2time(tournament.value.time_initial));
  const perMoveTime = computed(() => seconds2time(tournament.value.time_per_move));

  const participantsEloOrdered = computed(() => {
    return [...tournament.value.participants.sort((a, b) => {
      if (elo(a, tournament.value.format) === elo(b, tournament.value.format) || sortingMethod.value === t('name')) {
        if (a.last_name === b.last_name || a.last_name == null) {
          return a.first_name.localeCompare(b.first_name);
        }
        return a.last_name.localeCompare(b.last_name);
      }
      return elo(b, tournament.value.format) - elo(a, tournament.value.format)
    })]
  })

  function getParticipantEloIndex() {
    return tournament.value.participants.findIndex(participant => participant == store.getters.getUserParticipant);
  }

  const userActivationColor = computed(() => {
    return {
      'shadow-md shadow-success border-2 border-success': store.getters.getUserParticipant.checkin && store.getters.getUserParticipant.active,
      'shadow-md shadow-warning border-2 border-warning': store.getters.getUserParticipant.checkin && !store.getters.getUserParticipant.active,
      'shadow-md shadow-danger border-2 border-danger': !store.getters.getUserParticipant.checkin,
    }
  })

  async function toggleParticipantActivation(participant) {
    const response = await api.toggleParticipantActivation(participant);

    if(response.error.value) {
      toast.add({ severity: "error", summary: response.error.value.response.data.message, life: 3000 });
    }
  }

  async function deleteParticipant(participant) {
    const response = await api.deleteParticipant(participant)

    if (!response.error.value) {
      toast.add({ severity: 'success', summary: `Player ${participant.first_name} ${participant.last_name != null ? participant.last_name : ""} deleted`, life: 3000 });
    }
    if (response.error.value) {
      toast.add({ severity: 'error', summary: response.error.value.response.data.message, life: 3000 });
    }
  }

  function seconds2time(seconds) {
    const days = Math.floor(seconds / (24 * 3600));
    seconds %= 24 * 3600;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }
</script>

<template>
  <div data-index="infos">
    <!-- Tournament details -->
    <div class="bg-greyblue p-4 rounded-2xl">
      <div class="mb-3 flex justify-between items-center">
        <h1 class="text-3xl font-bold">{{ tournament.title }}</h1>
        <RouterLink :to="`/tournaments/${tournament.id}/edit`"
        v-if="isUserAdmin(tournament)">
          <button class="primary-btn">
            <i class="fa-solid fa-gear"></i>
            <p class="hidden sm:inline-block ml-2">{{ $t('config') }}</p>
          </button>
        </RouterLink>
      </div>

      <div class="flex flex-col mb-4 gap-3">
        <div class="flex flex-wrap gap-3 sm:gap-8">
          <div class="flex gap-2 items-center">
            <i class="fa-solid fa-calendar-day"></i>
            <p class="m-0 font-bold">{{ moment(tournament.date).format('DD/MM/YYYY') }}</p>
            <p v-show="tournament.end_date" class="m-0 font-bold"> - </p>
            <p v-show="tournament.end_date" class="m-0 font-bold">{{ moment(tournament.end_date).format('DD/MM/YYYY') }}</p>
          </div>
          <div class="flex gap-2 items-center">
            <i class="fa-regular fa-clock"></i>
            <p class="m-0 font-bold">{{ moment(tournament.date).format('HH:mm') }}</p>
          </div>
        </div>
        <div class="flex flex-wrap gap-3 sm:gap-8">
          <div class="flex gap-2 items-center">
            <i class="fa-solid fa-forward"></i>
            <p class="m-0 font-bold" v-t="`tournament.form.${tournament.format}`"></p>
          </div>
          <div class="flex gap-2 items-center">
            <i class="fa-solid fa-stopwatch"></i>
            <div class="flex gap-1">
              <p v-if="tournament.time_initial == null" class="m-0 font-bold">--</p>
              <div v-else class="flex gap-1">
                <p v-if="initTime.days" class="m-0 font-bold">{{ initTime.days }}d</p>
                <p v-if="initTime.hours" class="m-0 font-bold">{{ initTime.hours }}h</p>
                <p v-if="initTime.minutes" class="m-0 font-bold">{{ initTime.minutes }}m</p>
                <p v-if="initTime.seconds || tournament.time_initial == 0" class="m-0 font-bold">{{ initTime.seconds }}s</p>
              </div>
              <p class="m-0 font-bold">+</p>
              <p v-if="tournament.time_per_move == null" class="m-0 font-bold">--</p>
              <div v-else class="flex gap-1">
                <p v-if="perMoveTime.days" class="m-0 font-bold">{{ perMoveTime.days }}d</p>
                <p v-if="perMoveTime.hours" class="m-0 font-bold">{{ perMoveTime.hours }}h</p>
                <p v-if="perMoveTime.minutes" class="m-0 font-bold">{{ perMoveTime.minutes }}m</p>
                <p v-if="perMoveTime.seconds || tournament.time_per_move == 0" class="m-0 font-bold">{{ perMoveTime.seconds }}s</p>
              </div>
            </div>
          </div>
          <div class="flex gap-2 items-center">
            <i class="fa-solid fa-circle-notch"></i>
            <p class="m-0 font-bold">{{ tournament.max_rounds || "--" }}</p>
          </div>
        </div>
      </div>
      <div class="flex gap-2 items-center mb-4">
        <i class="fa-solid fa-location-dot"></i>
        <p class="m-0 font-bold">{{ tournament.address || "--" }}</p>
      </div>

      <Panel class="whitespace-pre-line" :header="$t('tournament.form.descriptionLabel')" :collapsed="true" toggleable>
        {{ tournament.description }}
      </Panel>
    </div>

    <!-- User action -->
    <TournamentUserButtons @toggleParticipant="(participant) => toggleParticipantActivation(participant)" />

    <!-- Participants list -->
    <div id="participants-table"
    class="bg-greyblue p-4 rounded-2xl mt-3 min-h-1/3">

      <div class="flex items-center justify-between mb-3">
        <h2 class="mb-0 font-bold text-2xl">{{ $t('Participants') }} - <span id="participants-count">{{ tournament.participants.length }} / {{ tournament.max_participants }}</span></h2>

        <div class="flex items-center gap-4"
        v-if="hasUserPlayerRights(tournament)">
          <RegistrationMenu />

          <AdminActionsMenu />
        </div>
      </div>
      <div v-if="store.getters.getUserParticipant"
      class="w-full flex justify-center">
        <div :key="store.getters.getUserParticipant.id"
        class="participant-registration relative flex bg-deep-greyblue opacity-90 overflow-hidden pl-14 pr-6 mt-2 mb-6 rounded-full items-center justify-between h-20 max-w-md basis-full"
        :class="userActivationColor">
          <ParticipantRegistration
          @toggleActivation="toggleParticipantActivation(store.getters.getUserParticipant)"
          @deleteParticipant="deleteParticipant(store.getters.getUserParticipant)"
          :participant="store.getters.getUserParticipant" :index="getParticipantEloIndex()" />
        </div>
      </div>
      <div class="mb-4 text-center">
        <SelectButton v-model="sortingMethod" :options="sortingOptions" aria-labelledby="basic" />
      </div>
      <div id="participants-list" class="flex flex-wrap gap-5 justify-center">
        <div :key="participant.id" id="participant.id"
        class="participant-registration relative flex bg-deep-greyblue overflow-hidden pl-14 pr-6 rounded-full items-center justify-between h-20 border-deep-skyblue drop-shadow-md max-w-md basis-full"
        v-for="(participant, index) in participantsEloOrdered">

          <ParticipantRegistration
            @toggleActivation="toggleParticipantActivation(participant)"
            @deleteParticipant="deleteParticipant(participant)"
            :participant="participant" :index="index" />
        </div>
      </div>
    </div>
  </div>
</template>
