import { createRouter, createWebHistory } from 'vue-router';
import store from './store';

import Home from '../components/Home.vue';
import TournamentIndex from '../components/TournamentIndex.vue';
import TournamentNew from '../components/TournamentNew.vue';
import TournamentShow from '../components/TournamentShow.vue';
import TournamentEdit from '../components/TournamentEdit.vue';
import Dashboard from '../components/Dashboard.vue';
import Login from '../components/Login.vue';
import Signup from '../components/Signup.vue';
import AccountConfirmation from '../components/AccountConfirmation.vue'
import PasswordReset from '../components/PasswordReset.vue';
import PasswordChange from '../components/PasswordChange.vue';
import ConfirmationInstructions from '../components/ConfirmationInstructions.vue';
import NotFound from '../components/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      auth: false,
    }
  },
  {
    path: '/tournaments',
    name: 'tournamentsIndex',
    component: TournamentIndex,
    meta: {
      auth: true,
    }
  },
  {
    path: '/tournaments/new',
    name: 'newTournament',
    component: TournamentNew,
    meta: {
      auth: true,
    }
  },
  {
    path: '/tournaments/:id',
    name: 'showTournament',
    component: TournamentShow
  },
  {
    path: '/tournaments/:id/edit',
    name: 'editTournament',
    component: TournamentEdit,
    meta: {
      auth: true,
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true,
    }
  },
  {
    path: '/users/password_change',
    name: 'passwordChange',
    component: PasswordChange,
    props: true,
    meta: {
      auth: false,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: router => ({ redirect: router.query.redirect || null }),
    meta: {
      auth: false,
    }
  },
  {
    path: '/password_reset',
    name: 'passwordReset',
    component: PasswordReset,
    props: router => ({ redirect: router.query.redirect || null }),
    meta: {
      auth: false,
    }
  },
  {
    path: '/users/confirmation_instructions',
    name: 'confirmationInstructions',
    component: ConfirmationInstructions,
    props: router => ({ redirect: router.query.redirect || null }),
    meta: {
      auth: false,
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    props: router => ({ redirect: router.query.redirect || null }),
    meta: {
      auth: false,
    }
  },
  {
    path: '/account_confirmation',
    name: 'accountConfirmation',
    component: AccountConfirmation,
    props: router => ({ redirect: router.query.redirect || null }),
    meta: {
      auth: false,
    }
  },
  {
    path: '/:pathMatch(.*)*', // Catch-all route for 404
    name: 'NotFound',
    component: NotFound,
  },
  // { path: '/:pathMatch(.*)*', redirect: '/' }, // Catch-all route
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// Authentification
async function authenticateUser() {
  let localAuthToken = localStorage.auth_token;
  let cookieExists = localAuthToken !== "undefined" && localAuthToken !== null;
  if (cookieExists && !store.getters.isAuthenticated) {
    const auth_token = localStorage.getItem("auth_token");
    const authTokenExist = auth_token !== "undefined" && auth_token !== null;
    if (authTokenExist) {
      try {
        await store.dispatch("loginUserWithToken", { auth_token });
      } catch (error) {
        store.commit('resetUserInfo');
      }
    }
  }
}

router.beforeEach(async (to, from, next) => {
  try {
    await authenticateUser()

    if (to.meta.auth === true) {
      // Redirect to login if not authenticated
      if (!store.getters.isAuthenticated) {
        next({ name: 'login' });
      } else {
        next();
      }
    } else if (to.meta.auth === false) {
      if (store.getters.isAuthenticated) {
        next({ name: 'tournamentsIndex' });
      } else {
        next();
      }
    } else {
      next(); // Continue navigation for all other routes
    }
  } catch {
    next({ name: 'login' });
  }
})

export default router;
