<script setup>
</script>

<template>
  <section class="">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
            <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">404</h1>
            <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">{{ $t('notFound.title') }}</p>
            <p class="mb-10 text-lg font-light text-gray-500 dark:text-gray-400">{{ $t("notFound.message") }}</p>
            <router-link to="/" class="primary-btn">{{ $t('notFound.returnBtn') }}</router-link>
        </div>
    </div>
  </section>
</template>
