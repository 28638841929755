<script setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { useToast } from 'primevue/usetoast';
  import api from '../entrypoints/api.js';
  import { useConfirm } from "primevue/useconfirm";
  import { useI18n } from 'vue-i18n';

  import TournamentCard from './TournamentCard.vue';

  const router = useRouter();
  const store = useStore();
  const { t } = useI18n();

  // Services init
  const confirm = useConfirm();
  const toast = useToast();

  // Variables
  const progressTournaments = ref([]);
  const upcomingTournaments = ref([]);
  const pastTournaments = ref([]);
  const user = ref(null);
  const loading = ref(false)
  const error = ref(null)

  function redirectToTournament(tournamentId) {
    router.push(`/tournaments/${tournamentId}`);
  }

  // View Init
  async function fetchData() {
    error.value = progressTournaments.value = upcomingTournaments.value = pastTournaments.value = []
    loading.value = true

    user.value = store.getters.getUser;
    try {
      const data = await api.getUserTournaments();

      progressTournaments.value = data.mapped_tournaments.filter(map_data => map_data.tournament.status === "running");
      upcomingTournaments.value = data.mapped_tournaments.filter(map_data => map_data.tournament.status === "pending");
      pastTournaments.value = data.mapped_tournaments.filter(map_data => map_data.tournament.status === "over");
    } catch (err) {
      error.value = err.toString();
    } finally {
      loading.value = false;
    }
  }

  function confirmDeleteTournament(tournament, event) {
    event.stopPropagation();
    confirm.require({
      header: t('confirm.deleteTournamentHeader', {title: tournament.title}),
      message: t('confirm.deleteTournamentMessage', {title: tournament.title}),
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: t('confirm.cancel'),
      acceptLabel: t('confirm.deleteTournament'),
      accept: () => deleteTournament(tournament),
      group: "dialog"
    });
  }

  async function deleteTournament(tournament) {
    const response = await api.deleteTournament(tournament.id)

    if(response.error.value) {
      toast.add({ severity:'error', summary:`Error deleting tournament ${tournament.title}`, details: response.error.value.message, life: 3000 });

    } else {
      upcomingTournaments.value = upcomingTournaments.value.filter((upTournament) => {
        return upTournament.tournament.id !== tournament.id
      });
      toast.add({ severity:'success', summary:`${tournament.title} deleted`, life: 3000 });
    }
  }

  fetchData();
</script>


<template>
  <Toast />

  <div class="text-end m-3">
    <RouterLink to="/tournaments/new" >
      <button class="bg-skyblue text-white font-bold py-2.5 px-4 rounded-md no-underline text-right" v-t="'tournament.index.newTournamentBtn'"></button>
    </RouterLink>
  </div>

  <div v-if="progressTournaments && progressTournaments.length"
  class="text-center mb-10">
    <h2 class="self-center text-2xl mb-3">{{ $t('tournament.index.progressTournaments')}}</h2>
    <div class="flex flex-col overflow-x-auto standings-pane p-2 m-3 rounded-lg border border-gray-500">

      <TournamentCard v-for="mapped_data in progressTournaments"
      :key="mapped_data.tournament.id"
      :mapped_data="mapped_data"
      :user="user"
      @click="redirectToTournament(mapped_data.tournament.id)"
      @deleteTournament="(tournament, event) => confirmDeleteTournament(tournament, event)">
      </TournamentCard>
    </div>
  </div>

  <div class="text-center mb-10">
    <h2 class="self-center text-2xl mb-3">{{ $t('tournament.index.upcomingTournaments')}}</h2>
    <div class="flex flex-col justify-center overflow-x-auto standings-pane p-2 m-3 rounded-lg border border-gray-500 min-h-16">
      <p v-if="loading">{{ $t('loading') }}...</p>

      <TournamentCard v-else-if="upcomingTournaments.length"
      v-for="mapped_data in upcomingTournaments"
      :key="mapped_data.tournament.id"
      :mapped_data="mapped_data"
      :user="user"
      @click="redirectToTournament(mapped_data.tournament.id)"
      @deleteTournament="(tournament, event) => confirmDeleteTournament(tournament, event)">
      </TournamentCard>

      <p v-else>{{ $t('tournament.index.noUpcomingTournament') }}</p>
    </div>
  </div>

  <div class="text-center">
    <h2 class="self-center text-2xl mb-3">{{ $t('tournament.index.tournamentsHistory') }}</h2>
    <div id="tournament-incoming"
    class="flex flex-col justify-center overflow-x-auto standings-pane p-2 m-3 rounded-lg border border-gray-500 min-h-16">

      <div v-if="loading">
        <p class="text-center">{{ $t('loading') }}...</p>
      </div>

      <TournamentCard v-else-if="pastTournaments.length"
      v-for="mapped_data in pastTournaments"
      :key="mapped_data.tournament.id"
      :mapped_data="mapped_data"
      :user="user"
      @click="redirectToTournament(mapped_data.tournament.id)"
      @deleteTournament="(tournament, event) => confirmDeleteTournament(tournament, event)">
      </TournamentCard>

      <div v-else>
        <p class="text-center">{{ $t('tournament.index.noTournamentHistory') }}</p>
      </div>
    </div>
</div>
</template>
