<script setup>
  import { ref } from 'vue';
  import { useConfirm } from "primevue/useconfirm";
  import api from '../entrypoints/api.js'
  import { useToast } from 'primevue/usetoast';
  import { useI18n } from 'vue-i18n';
  import axios from "axios";
  import { useStore } from 'vuex';

  const store = useStore();
  const confirm = useConfirm();
  const toast = useToast();
  const { t } = useI18n();

  const adminActionsMenu = ref();
  const adminActionsItems = ref([
    {
      label: t('adminActions.activationsSectionTitle'),
      items: [
        {
          label: t('adminActions.activateAll'),
          icon: 'fa-regular fa-eye text-success',
          command: () => toggle_participant_activation('activate'),
        },
        {
          label: t('adminActions.deactivateAll'),
          icon: 'fa-regular fa-eye-slash text-warning',
          command: () => toggle_participant_activation('deactivate'),
        },
      ],
    },
    {
      label: t('adminActions.checkinSectionTitle'),
      items: [
        {
          label: t('adminActions.checkAll'),
          icon: 'fa-solid fa-check text-success text-lg',
          command: () => set_all_participants_checkin('on'),
        },
        {
          label: t('adminActions.uncheckAll'),
          icon: 'fa-solid fa-xmark text-danger text-lg',
          command: () => set_all_participants_checkin('off'),
        },
      ],
    },
    {
      label: t('adminActions.registrationsSectionTitle'),
      items: [
        {
          label: t('adminActions.refreshAll'),
          icon: 'fa-solid fa-arrow-rotate-right text-blue-300',
          class: 'text-primary',
          command: () => refreshAllParticipants(),
        },
        {
          label: t('adminActions.deleteAll'),
          icon: 'pi pi-user-minus text-danger',
          class: 'text-danger',
          command: () => confirmDeleteAllParticipants(),
        },
      ],
    },
  ]);

  function refreshAllParticipants() {
    axios.get(`/api/tournaments/${store.getters.getTournament.id}/refresh_participants`)
    .then(() => {
      toast.add({ severity: "success", summary: "Participants have been updated", life: 3000 });
    })
    .catch(error => {
      toast.add({ severity: "error", summary: error.response.data.message, life: 3000 });
    });
  }

  async function toggle_participant_activation(status) {
    const response = await api.toggleAllParticipantsActivation(store.getters.getTournament.id, status);

    if (response.error) {
      toast.add({ severity: "error", summary: response.error.response.data.message, life: 3000 });
    }
  }

  function set_all_participants_checkin(check_status) {
    axios.put(`/api/tournaments/${store.getters.getTournament.id}/set_all_checkin`, { check_status: check_status })
    .then(response => {
      const success_message = `All players successfully ${check_status === "on" ? "checked" : "unchecked" }`;
      toast.add({ severity: "success", summary: success_message, life: 3000 });
    })
    .catch(error => {
      toast.add({ severity: "error", summary: error.response.data.message, life: 3000 });
    })
  }

  function confirmDeleteAllParticipants() {
    confirm.require({
      header: t('confirm.deleteAllParticipantsHeader'),
      message: t('confirm.deleteAllParticipantsMessage'),
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: t('confirm.cancel'),
      acceptLabel: t('confirm.deleteAll'),
      accept: () => deleteAllParticipants(),
      group: 'dialog',
    })
  }

  async function deleteAllParticipants() {
    const response = await api.deleteAllParticipants(store.getters.getTournament)

    if (!response.error.value) {
      toast.add({ severity: 'success', summary: `All players deleted successfully`, life: 3000 });
    }
    if (response.error.value) {
      toast.add({ severity: 'error', summary: response.error.value.response.data.message, life: 3000 });
    }
  }

  const toggleAdminActionsMenu = (event) => {
    adminActionsMenu.value.toggle(event);
  }
</script>


<template>
  <div v-if="store.getters.getTournament.status != 'over'">
    <button class='primary-btn' type="button" @click="toggleAdminActionsMenu" aria-haspopup="true" aria-controls="admin_actions_menu">
      <i class="pi pi-ellipsis-v text-xl align-middle"></i>
    </button>
    <Menu ref="adminActionsMenu" id="admin_actions_menu" :model="adminActionsItems" :popup="true" />
  </div>
</template>
