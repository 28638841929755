<script setup>
  import { ref, computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import api from '../entrypoints/api';

  const store = useStore();

  const pairing = computed(() => store.getters.getUserCurrentPairing)
  const pairingResult = ref("pending");
  const userResult = computed(() => store.getters.getUserParticipant.id === store.getters.getUserCurrentPairing.white_player_id ? store.getters.getUserCurrentPairing.white_result : store.getters.getUserCurrentPairing.black_result)
  const pendingSendResult = ref(false);

  watch((() => store.getters.getUserCurrentPairing.result), () => {
    if (pairing.value.result === "pending")
      pairingResult.value = "pending";
  } )

  async function updateResult() {
    pendingSendResult.value = true;
    const response = await api.updatePairingResult({ pairingId: store.getters.getUserCurrentPairing.id, result: pairingResult.value, override: false });
    pendingSendResult.value = false;

    if (response.error) {
      toast.add({ severity: "error", summary: response.error.response.data.message, life: 3000 });
    }
  }
</script>

<template>
  <div id="player-table-result"
  class="rounded-md md:my-6 my-4 p-2 text-center shadow-lg border-2 border-deep-blue"
  :class="{
    'bg-light-grey text-black': pairing.result == 'white_win' || pairing.result == 'white_win_by_forfeit',
    'bg-black text-white': pairing.result == 'black_win' || pairing.result == 'black_win_by_forfeit',
    'bg-gray-500': pairing.result == 'draw',
    'bg-danger text-white': pairing.result == 'conflict',
    'bg-dark-greyblue': !pairing.result,
   }">

    <p v-if="pairing.result === 'white_win' || pairing.result === 'white_win_by_forfeit'" v-t="'tableResultWhiteWins'" class="text-lg m-2"></p>
    <p v-else-if="pairing.result === 'black_win' || pairing.result === 'black_win_by_forfeit'" v-t="'tableResultBlackWins'" class="text-lg m-2" ></p>
    <p v-else-if="pairing.result === 'draw'" v-t="'tableResultDraw'" class="text-lg m-2"></p>
    <p v-else-if="pairing.result === 'conflict'" v-t="'tableResultConflict'" class="text-lg m-2" ></p>

    <div v-if="!userResult && !pairing.admin_result_override">
      <div v-if="store.getters.isAuthenticated" class="flex items-center justify-center">
        <select v-model="pairingResult"
        class="secondary-btn mx-3 my-2">
          <option disabled value='pending' v-t="'tableResultSelectDefault'">
              <div class="text-light-gray text-lg">
                <i class="fa-solid fa-chevron-down"></i>
              </div>
          </option>
          <option value="i_won" v-t="'tableResultSelectWon'"></option>
          <option value="i_won_by_forfeit" v-t="'tableResultSelectWonByForfeit'"></option>
          <option value="i_lost" v-t="'tableResultSelectLost'"></option>
          <option value="draw" v-t="'tableResultSelectDraw'"></option>
        </select>
        <div>
          <button v-show="!pendingSendResult" class="primary-btn"
          :class="{ 'opacity-50': pairingResult == 'pending' }"
          @click="updateResult"
          :disabled="pairingResult == 'pending'"
          v-t="'confirmBtn'"></button>
          <ProgressSpinner v-show="pendingSendResult" style="width:50px; height:50px" strokeWidth="4" />
        </div>
      </div>
      <div v-else>
        <p class="mb-3">{{ $t('tableResultLoggedOutUserMessage') }}</p>
        <button @click="$router.push({ path: '/login', query: { redirect: $route.fullPath }})" class="primary-btn">{{ $t('users.login') }}</button>
      </div>
    </div>
  </div>
</template>
