<script setup>
</script>

<template>
  <div class="text-center w-full">
    <div class="mt-32">
      <img class="w-1/3 max-w-40 m-auto mb-6" src="/../assets/images/logo.png" alt="Logo">
      <div class="">
        <h1 class="mb-6 text-2xl font-mono">{{ $t('home.title') }}</h1>
        <h2 class="mb-6 text-lg">{{ $t('home.description') }}</h2>
        <div class="mb-6"><button @click="$router.push('/signup')" class="primary-btn">{{ $t('users.signup') }}</button></div>
        <div><button @click="$router.push('/login')" class="secondary-btn">{{ $t('users.login') }}</button></div>
      </div>
    </div>
  </div>
</template>
